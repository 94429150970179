import * as React from "react"
import styled from "styled-components";
import GooglePlay from "../assets/images/google-play.svg";
import AppStore from "../assets/images/app-store.svg";
import {StaticImage} from "gatsby-plugin-image";
import TagIcon from "../assets/icons/tag-icon.svg";
import ReuseIcon from "../assets/icons/reuse-icon.svg";
import SaveMoneyIcon from "../assets/icons/save-money-icon.svg";
import SEO from "../components/SEO";

const StyledMainContent = styled.main`
  padding: var(--main-padding-mob);
  @media only screen and (min-width: 600px ) {
    padding: var(--main-padding-tab);
  }
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 1025px ) {
    flex-direction: row;
    justify-content: center;
  }
`;

const HeroTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 1025px ) {
    align-items: start;
    
  }
`;

const StyledHeading = styled.h1`
  font-size: 2rem;
  margin-bottom: 25px;
  text-align: center;
  color: var(--primary-text-color);
  @media only screen and (min-width: 1025px ) {
    text-align: left;
    max-width: 350px;
  }
`;

const StyledParagraph = styled.p`
  font-size: 1rem;
  text-align: center;
  color: var(--primary-text-color);
  font-weight: normal;

  @media only screen and (min-width: 1025px ) {
    text-align: left;
  }
`;

const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  
  @media only screen and (min-width: 1025px ) {
    flex-direction: row;
    align-items: center;
    
    &:first-of-type{
      margin-top: 80px;
    }
    
  }
`;

const GooglePlayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 32px;
  border: 2px solid var(--gold-highlight-color);
  border-radius: 10px;
`;

const AppStoreContainer = styled.div`
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 32px;
  background: var(--gold-highlight-color);
  border-radius: 10px;
  overflow: hidden;
  
  &:after{
    position: absolute;
    display: flex;
    justify-content: center;
    background: var(--red-highlight-color);
    width: 100%;
    top: 0;
    font-size: 1rem;
    content: "Coming Soon!";
  }

  @media only screen and (min-width: 1025px ) {
    margin-top: 0;
    margin-left: 20px;
  }
`;

const StyledStoreImage = styled.img`  
  &:not(:last-child){
    margin-bottom: 20px;
  }
`;

const ImageContainer = styled.div`
  margin-top: 40px;
  box-shadow: -4px -4px 10px 2px rgba(58, 58, 58, .7), 4px 4px 10px 0 rgba(0, 0, 0, .7);
  border-radius: 20px;
  align-self: center;

  @media only screen and (min-width: 1025px ) {
    margin-top: 0;
  }
`;

const HeroImageContainer = styled(ImageContainer)`
  @media only screen and (min-width: 1025px ) {
    margin-left: 150px;
    margin-top: 40px;
  }
`;

const FeatureSection = styled.section`
  margin-top: -40px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 1025px ) {
    margin-top: -60px;
    padding-top: 140px;
  }
`;

const FeatureTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 1025px ) {
    align-items: start;
  }
`;

const Feature1TextContainer = styled(FeatureTextContainer)`
  @media only screen and (min-width: 1025px ) {
    margin-right: 40px;
  }
`;

const Feature2TextContainer = styled(FeatureTextContainer)`
  @media only screen and (min-width: 1025px ) {
    margin-left: 40px;
  }
`;

const Feature3TextContainer = styled(FeatureTextContainer)`
  @media only screen and (min-width: 1025px ) {
    margin-bottom: 40px;
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FeatureContainer1 = styled(FeatureContainer)`
  @media only screen and (min-width: 1025px ) {
    flex-direction: row;
    margin-bottom: 80px;
  }
`;

const FeatureContainer2 = styled(FeatureContainer)`
  
  @media only screen and (min-width: 1025px ) {

    flex-direction: row-reverse;
    & ${FeatureTextContainer} {
      align-self: start;
    }
    
    & ${ImageContainer}{
      align-self: start;      
    }
  }
`;

const FeatureContainer3 = styled(FeatureContainer)`
  @media only screen and (min-width: 1025px ) {
    margin-left: 80px;
  }  
`;

const FeatureColumnHelper = styled.div`
  display: flex;
  flex-direction: column;  
`;

const FeatureBody = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1025px ) {
    flex-direction: row;
    margin-top: 80px;
  }
`;

const SectionIcon = styled.img`
  margin-top: 40px;

  @media only screen and (min-width: 1025px ) {
    margin-top: 0;
  }
`;

const StyledSubHeading = styled.h2`
  text-align: center;
  color: var(--primary-text-color);
  font-size: 1.75rem;
  font-weight: bold;
`;

const FeatureTitle = styled.h3`
  margin-top: 20px;
  font-size: 1.25rem;
  text-align: center;
  color: var(--green-highlight-color);

  @media only screen and (min-width: 1025px ) {
    text-align: left;
  }
`;

const FeatureTitleRed = styled(FeatureTitle)`
  color: var(--red-highlight-color);
`;

const FeatureTitleBlue = styled(FeatureTitle)`
  color: var(--blue-highlight-color);
`;

const FeatureText = styled.p`
  margin-top: 20px;
  color: var(--primary-text-color);
  text-align: center;
  font-size: 1rem;

  @media only screen and (min-width: 1025px ) {
    max-width: 250px;
    text-align: left;
  }
`;

const BonusSection = styled(FeatureSection)`
  align-items: start;

  @media only screen and (min-width: 1025px ) {
    align-items: center;
  }
`;

const BonusSectionBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  @media only screen and (min-width: 1025px ) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const BonusColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const BonusColumn1 = styled(BonusColumn)`
  margin-top: 40px;
  
  @media only screen and (min-width: 1025px ) {
    margin-right: 100px;
    margin-top: 80px;
  }
`;

const BonusColumn2 = styled(BonusColumn)`
  margin-top: 40px;

  @media only screen and (min-width: 1025px ) {
    margin-top: 80px;
  }
  
`;

const BonusContainer = styled.div`  
  position: relative;
  color: var(--primary-text-color);
  
  
  &:not(:first-of-type){
    margin-top: 40px;
  }
  
  &:before{
    content: " ";
    position: absolute;
    left: -24px;
    width: 3px;
    height: 100%;
    background: var(--green-highlight-color);
  }
`;

const BonusSectionSubHeading = styled(StyledSubHeading)`
  align-self: center;
`;

const BonusTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  
`;

const BonusText = styled.p`
  font-size: 1rem;
  font-weight: normal;
  margin-top: 10px;

  @media only screen and (min-width: 1025px ) {
    max-width: 400px;
  }
`;

const BonusContainerRed = styled(BonusContainer)`
  &:before{
    background: var(--red-highlight-color);
  }
`;

const BonusContainerBlue = styled(BonusContainer)`
  &:before{
    background: var(--blue-highlight-color);
  }
`;

const DownloadSection = styled(FeatureSection)`
`;

const DownloadSectionImage = styled(ImageContainer)`
  @media only screen and (min-width: 1025px ) {
    margin-top: 40px;
  }
`;

const IndexPage = ({location}) => {
  return <StyledMainContent>
    <SEO
        title="Going Shopping - The Only Shopping List App You Need"
        description="Use our free grocery list app to save time and money, optimize your cooking and meal planning, and reduce food waste. Download here!"
        location={location}
    />
    <HeroSection>
      <HeroTitleContainer>
        <StyledHeading>The Easiest Shopping List App</StyledHeading>
        <StyledParagraph>Save time. Reduce food waste.<br/> Never again forget to buy something!</StyledParagraph>
        <StoreContainer>
          <a aria-label="Open Google Play Store Going Shopping page" href="https://play.google.com/store/apps/details?id=com.sqrchicken.going_shopping">
            <GooglePlayContainer>
              <StyledStoreImage src={GooglePlay} alt="" height="36px" width="120px"/>
            </GooglePlayContainer>
          </a>
          <AppStoreContainer>
            <StyledStoreImage src={AppStore} alt="" height="36px" width="120px"/>
          </AppStoreContainer>
        </StoreContainer>
      </HeroTitleContainer>
      <HeroImageContainer>
          <StaticImage src="../assets/images/mobile-ss-01.png" height={ 600 } alt="Application Preview"/>
      </HeroImageContainer>
    </HeroSection>
    <FeatureSection id="feature-section">
      <StyledSubHeading>
        What you can do with our app
      </StyledSubHeading>
      <FeatureBody>
        <FeatureColumnHelper>
          <FeatureContainer1>
            <Feature1TextContainer>
              <SectionIcon src={TagIcon} alt="" height="40px"/>
              <FeatureTitle>Tag & Color Code</FeatureTitle>
              <FeatureText>Make full use of our simple tagging and color coding system to organize your shopping lists by stores, dates, projects, recipes, and more.</FeatureText>
            </Feature1TextContainer>
            <ImageContainer>
                <StaticImage src="../assets/images/mobile-ss-02.png" height={600} alt="Tag and color code example"/>
            </ImageContainer>
          </FeatureContainer1>
          <FeatureContainer2>
            <Feature2TextContainer>
              <SectionIcon src={SaveMoneyIcon} alt="" height="40px"/>
              <FeatureTitleBlue>Save Money</FeatureTitleBlue>
              <FeatureText>Enter prices for the items you regularly buy so you know exactly how much you’re going to spend on each shopping trip.</FeatureText>
            </Feature2TextContainer>
            <ImageContainer>
                <StaticImage src="../assets/images/mobile-ss-04.png" height={600} alt="Pricing example"/>
            </ImageContainer>
          </FeatureContainer2>
        </FeatureColumnHelper>
        <FeatureContainer3>
          <Feature3TextContainer>
            <SectionIcon src={ReuseIcon} alt="" height="40px"/>
            <FeatureTitleRed>Reuse Lists</FeatureTitleRed>
            <FeatureText>Save your most frequently used shopping lists as templates and load them up whenever you want them again!</FeatureText>
          </Feature3TextContainer>
          <ImageContainer>
              <StaticImage src="../assets/images/mobile-ss-03.png" height={600} alt="Reuse lists example"/>
          </ImageContainer>
        </FeatureContainer3>
      </FeatureBody>
    </FeatureSection>
    <BonusSection>
      <BonusSectionSubHeading>Bonus features</BonusSectionSubHeading>
      <BonusSectionBody>
        <BonusColumn1>
          <BonusContainer>
            <BonusTitle>Unlimited Lists With Unlimited Items</BonusTitle>
            <BonusText>No restrictions in creating shopping lists!</BonusText>
          </BonusContainer>
          <BonusContainerRed>
            <BonusTitle>All Currencies</BonusTitle>
            <BonusText>Choose a currency for the pricing that works for you wherever you happen to be in the world.</BonusText>
          </BonusContainerRed>
          <BonusContainerBlue>
            <BonusTitle>Quantities And Units Of Measure</BonusTitle>
            <BonusText>Add the precise amount of any item you want, as well as a unit of measure you commonly use.</BonusText>
          </BonusContainerBlue>
        </BonusColumn1>
        <BonusColumn2>
          <BonusContainer>
            <BonusTitle>Offline And Online</BonusTitle>
            <BonusText>Use our grocery list app regardless of whether or not you are connected to the internet!</BonusText>
          </BonusContainer>
          <BonusContainerRed>
            <BonusTitle>Multiple Languages</BonusTitle>
            <BonusText>Available in English, German, and Serbian, with more languages to come!</BonusText>
          </BonusContainerRed>
          <BonusContainerBlue>
            <BonusTitle>Share With Your Loved Ones</BonusTitle>
            <BonusText>Share your lists via external apps so everyone can get involved with shopping!</BonusText>
          </BonusContainerBlue>
        </BonusColumn2>
      </BonusSectionBody>
    </BonusSection>
    <DownloadSection id="download-section">
      <StyledSubHeading>Download now - it’s free!</StyledSubHeading>
      <StoreContainer>
        <a aria-label="Open Google Play Store Going Shopping page" href="https://play.google.com/store/apps/details?id=com.sqrchicken.going_shopping">
          <GooglePlayContainer>
            <StyledStoreImage src={GooglePlay} alt="" height="36px" width="120px"/>
          </GooglePlayContainer>
        </a>
        <AppStoreContainer>
          <StyledStoreImage src={AppStore} alt="" height="36px" width="120px"/>
        </AppStoreContainer>
      </StoreContainer>
      <DownloadSectionImage>
          <StaticImage src="../assets/images/mobile-ss-05.png" height={600} alt="Application preview"/>
      </DownloadSectionImage>
    </DownloadSection>
  </StyledMainContent>
}

export default IndexPage;